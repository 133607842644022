import axios from 'axios';

export default {
    getList(filters) {
        return axios.get('/cabinet/provider/productions', { params: filters });
    },
    get(productionId) {
        return axios.get('/cabinet/provider/productions/' + productionId);
    },
    save(payload) {
        return axios.post('/cabinet/provider/productions', payload);
    },
    update(productionId, payload) {
        return axios.put('/cabinet/provider/productions/' + productionId, payload);
    },
    updateProductionLocation(productionLocation) {
        return axios.put('/cabinet/provider/production_location', { productionLocation });
    },
    deleteProduction(productionId) {
        return axios.delete('/cabinet/provider/productions/' + productionId);
    }
};
